<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE ACCOUNT PAY HEADER</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Update Data Account Pay Header
        <br />
        <h4>Please enter update data lists account pay header</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Update Account Pay (Header)</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Number of Evidence</label>
                  <input
                    type="text"
                    v-model="isidata.nomorbukti"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Number of Evidence"
                  />
                  <input
                    type="hidden"
                    v-model="isidata.idxaccaph"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Date of Evidence</label>
                  <input
                    type="date"
                    v-model="isidata.tglbukti"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.keterangan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Supplier</label>
                  <select2
                    :data="cdsup"
                    :value="valuesup"
                    @update="updatesup($event)"
                  ></select2>
                  <!--<select v-model="isidata.supkode" class="form-control">
                    <option
                      v-for="datasupkode in cdsup"
                      :key="datasupkode.id"
                      :value="datasupkode.kode_supplier"
                    >
                      {{ datasupkode.nama_supplier }}
                    </option>
                  </select>-->
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/accountpay">
                  <button type="button" class="btn btn-info">
                    <i class="fa fa-angle-double-left"> Back </i>
                  </button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
// import VPagination from "@hennge/vue3-pagination";
// import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vggg",
  /* components: {
    VPagination,
  }, */
  data() {
    return {
      isidata: {
        nomorbukti: "",
        tglbukti: "",
        keterangan: "",
        kodecoa: "",
        catatdet: "",
        refen: "",
        deb: "",
        cre: "",
        supkode: "",
        idxaccaph: "",
      },
      loading: false,
      valuesup: "",
      cdsup: [],
    };
  },
  created() {
    this.listsupplier();
    //this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget = this.$apiurl + "acc_ap_h/getacc_ap_hbyid?id=" + this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          console.log(resp)
          this.isidata.idxaccaph = resp.data.data.id;
          this.isidata.nomorbukti = resp.data.data.no_bukti;
          this.isidata.tglbukti = resp.data.data.tanggal_bukti;
          this.isidata.keterangan = resp.data.data.keterangan;
          this.valuesup = resp.data.data.kode_supplier;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateData() {
      this.loading = true;
      // alert(this.$route.params.id);
      var nm = this.isidata.nomorbukti;
      var tgl = this.isidata.tglbukti;
      var ket = this.isidata.keterangan;
      var spkode = this.valuesup;
      var idx = this.isidata.idxaccaph;
      if (nm == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Number of Evidence can not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (tgl == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Date of Evidence can not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (ket == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (spkode == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Supplier can not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          no_bukti: nm,
          tanggal_bukti: tgl,
          keterangan: ket,
          kode_user: kodeuser,
          kode_supplier: spkode,
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIUpdate = "http://26.183.23.191/inventory/backend/inventory/api/supplier/updatesupplier/" + idx;
        const urlAPIUpdate = this.$apiurl + "acc_ap_h/updateacc_ap_h/" + idx;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin,
        };
        axios
          .put(urlAPIUpdate, paramdata, { headers: headers })
          .then((resp) => {
            // console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false,
              });
              this.isidata.idxaccaph = "";
              this.isidata.nomorbukti = "";
              this.isidata.tglbukti = "";
              this.isidata.keterangan = "";
              this.valuesup = "";
              this.loading = false;
              this.$router.push({ name: "accountpayheader" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false,
              });
              this.loading = false;
              // this.fetchData();
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false,
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    
    updatesup(value) {
      this.valuesup = value;
    },
    listsupplier() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getalldatabarang?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl + "supplier/getalldatasupplier?length=12500&page=1";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          this.cdsup = resp.data.data;
          this.cdsup.forEach((item) => {
            item.value = item.kode_supplier;
            item.label = item.nama_supplier;
          });
          this.loading = false;
          this.loadData()
        })
        .catch((err) => {
          console.log(err.reponse.data);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
  },
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
